export default ({
  req,
  res,
  route,
  app,
  $axios,
  redirect,
  env
}) => {
  $axios.onResponse((response) => {
    if(response.status == 205 && (response.data||{}).newurl){
      redirect(301,(response.data.newurl||'').replace(/\ /g,''))
    }
  });
  $axios.onRequest((config) => {
    if(config.headers.common && !(config.headers.common||{}).shopster){
      if(process.server){
        config.headers.common.shopster = req.headers.host + route.fullPath.split('-')[0]
      }else{
        config.headers.common.shopster = location.host + route.fullPath.split('-')[0]
      }
    }
  });
}
